import React, { useState } from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components/macro";
import { bsrBlue } from "../../bsr-defs";
import { submitForm } from "../../../api";
import { useLocation } from "../../../router";
import "moment/locale/de";
import CustomRadio from "../components/CustomRadio";
import CustomTextarea from "../components/CustomTextarea";
import CustomInput from "../components/CustomInput";
import CustomCheckbox from "../components/CustomCheckbox";
import { IComponent } from "../../../Component";
import CustomSelect from "../components/CustomSelect";

export const FORM_WEITERBILDUNG = "form_weiterbildung";

export interface FormWeiterbildungSpec {
  type: typeof FORM_WEITERBILDUNG;
}

const MultiLine = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
`;

const MultiLineGrow = styled.div`
  flex-grow: 1;
  min-width: 0;
`;

const MultiLineGrowSecond = styled.div`
  flex-grow: 1;
  margin-left: 10px;
  min-width: 0;
`;

const SuccessBox = styled.div`
  max-width: 768px;
  background-color: #1a7f1f;
  padding: 25px;
  color: #fff;
  h1 {
    font-size: 1.5em;
    margin: 0px;
  }
`;

const ErrorBox = styled.div`
  max-width: 768px;
  background-color: #c00d00;
  padding: 25px;
  color: #fff;
  h1 {
    font-size: 1.5em;
    margin: 0px;
  }
`;

const FormBox = styled.div`
  max-width: 768px;
`;

const SectionTitle = styled.h3`
  margin-bottom: 5px;
`;

const SubmitButton = styled.button`
  padding: 10px;
  padding-left: 15px;
  padding-right: 15px;
  background: ${bsrBlue};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  text-decoration: none;
  color: white;
  white-space: nowrap;
  text-overflow: ellipsis;
  border-radius: 3px;
  max-width: fit-content;
  border-width: 0px;
`;

const SubmitBox = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 15px;
  margin-bottom: 5px;
`;

const InfoText = styled.p`
  margin-bottom: 0px;
  margin-top: 20px;
`;

const Component = () => {
  const location = useLocation();
  const {
    register,
    handleSubmit,
    formState,
    control,
  } = useForm();

  const {
    errors,
  } = formState;

  const [success, setSuccess] = useState(false);
  const [err, setErr] = useState(false);
  const onSubmit = (data: any) => {
    data.path = location;
    data.form_id = "4";

    console.log(data);

    submitForm(data)
      .then(() => {
        setSuccess(true);
      })
      .catch(() => {
        setErr(true);
      });
  };

  if (success) {
    return (
      <SuccessBox>
        <h1>Anmeldung erfolgreich</h1>
        <h3>
          Besten Dank für Ihre Anmeldung. Das Sekretariat wird Ihre Anmeldung
          schnellstmöglich verarbeiten und sich bei Ihnen melden.
        </h3>
      </SuccessBox>
    );
  }

  if (err) {
    return (
      <ErrorBox>
        <h1>Fehler</h1>
        <h3>
          Es ist ein Fehler bei der Verarbeitung aufgetreten. Bitte probieren
          sie es in einigen Minuten noch einmal oder wenden sie sich an
          office@bsrueti.ch.
        </h3>
      </ErrorBox>
    );
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormBox>
        <h1>Anmeldung Weiterbildung</h1>
        {/* Kurs */}
        <SectionTitle>Kurs</SectionTitle>
        <CustomInput
          name="Bezeichnung Kurs"
          label="Bezeichnung Kurs *"
          register={register}
          required={true}
          errors={errors}
          control={control}
        />
        <CustomInput
          name="Nummer Kurs"
          label="Nummer Kurs (WBxxx) *"
          register={register}
          required={true}
          errors={errors}
          control={control}
        />
        <CustomSelect
          name="Wochentag Kurs"
          label="Wochentag Kurs *"
          values={["Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag"]}
          register={register}
          required={true}
          errors={errors}
          control={control}
        />

        <SectionTitle>Personalien</SectionTitle>
        <CustomRadio
          name="Anrede"
          label="Anrede *"
          values={["Frau", "Herr", "andere"]}
          register={register}
          required={true}
          errors={errors}
          formState={formState}
        />
        <MultiLine>
          <MultiLineGrow>
            <CustomInput
              name="Vorname"
              label="Vorname *"
              register={register}
              required={true}
              errors={errors}
              control={control}
            />
          </MultiLineGrow>
          <MultiLineGrowSecond>
            <CustomInput
              name="Nachname"
              label="Nachname *"
              register={register}
              required={true}
              errors={errors}
              control={control}
            />
          </MultiLineGrowSecond>
        </MultiLine>
        <CustomInput
          name="Strasse"
          label="Strasse *"
          register={register}
          required={true}
          errors={errors}
          control={control}
        />
        <MultiLine>
          <CustomInput
            name="PLZ"
            label="PLZ *"
            register={register}
            required={true}
            errors={errors}
            control={control}
          />
          <MultiLineGrowSecond>
            <CustomInput
              name="Ort"
              label="Ort *"
              register={register}
              required={true}
              errors={errors}
              control={control}
            />
          </MultiLineGrowSecond>
        </MultiLine>
        <CustomInput
          name="Email"
          label="Email *"
          type="email"
          register={register}
          required={true}
          errors={errors}
          control={control}
        />
        <CustomInput
          name="Telefon"
          register={register}
          required={false}
          errors={errors}
          control={control}
        />
        <CustomInput
          name="Geburtsdatum"
          register={register}
          required={false}
          errors={errors}
          control={control}
        />
        <CustomSelect
          name="Kanton"
          values={[
            "Aargau",
            "Appenzell Innerrhoden",
            "Appenzell Ausserrhoden",
            "Basel-Landschaft",
            "Basel-Stadt",
            "Bern",
            "Freiburg",
            "Genf",
            "Glarus",
            "Graubünden",
            "Jura",
            "Luzern",
            "Neuenburg",
            "Nidwalden",
            "Obwalden",
            "Schaffhausen",
            "Schwyz",
            "Solothurn",
            "St. Gallen",
            "Tessin",
            "Thurgau",
            "Uri",
            "Waadt",
            "Wallis",
            "Zug",
            "Zürich",
          ]}
          register={register}
          required={false}
          errors={errors}
          control={control}
        />
        <CustomSelect
          name="Arbeitskanton"
          values={[
            "Aargau",
            "Appenzell Innerrhoden",
            "Appenzell Ausserrhoden",
            "Basel-Landschaft",
            "Basel-Stadt",
            "Bern",
            "Freiburg",
            "Genf",
            "Glarus",
            "Graubünden",
            "Jura",
            "Luzern",
            "Neuenburg",
            "Nidwalden",
            "Obwalden",
            "Schaffhausen",
            "Schwyz",
            "Solothurn",
            "St. Gallen",
            "Tessin",
            "Thurgau",
            "Uri",
            "Waadt",
            "Wallis",
            "Zug",
            "Zürich",
          ]}
          register={register}
          required={false}
          errors={errors}
          control={control}
        />

        {/* KLEINGRUPPEN */}
        <SectionTitle>Kleingruppen</SectionTitle>
        {/* Info über Zuschlag radio ja, nein*/}
        <InfoText>
          Für Kleingruppen mit weniger als 10 Teilnehmer wird ein Zuschlag
          erhoben. Ich möchte betreffend Zuschlag vor Kursstart informiert
          werden:
        </InfoText>
        <CustomRadio
          name="Info über Zuschlag"
          label="Info über Zuschlag *"
          values={["Ja", "Nein"]}
          register={register}
          required={true}
          errors={errors}
          formState={formState}
        />

        {/* Preiszuschlag ok radio ja, nein */}
        <InfoText>
          Ich bin mit dem Preiszuschlag bis max. 50% für Kleingruppen
          einverstanden:
        </InfoText>
        <CustomRadio
          name="Preiszuschlag"
          label="Preiszuschlag *"
          values={["Ja", "Nein"]}
          register={register}
          required={true}
          errors={errors}
          formState={formState}
        />

        <SectionTitle>Bemerkungen</SectionTitle>
        <CustomTextarea
          name="Bemerkungen"
          register={register}
          required={false}
          errors={errors}
          formState={formState}
        />
        <SubmitBox>
          <SubmitButton type="submit">Anmeldung senden</SubmitButton>
        </SubmitBox>
      </FormBox>
    </form>
  );
};

export const FormWeiterbildungComponent: IComponent<FormWeiterbildungSpec> = {
  component: Component,
};
