import React, { useState } from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components/macro";
import { bsrBlue } from "../../bsr-defs";
import { submitForm } from "../../../api";
import { useLocation } from "../../../router";
import "moment/locale/de";
import CustomRadio from "../components/CustomRadio";
import CustomTextarea from "../components/CustomTextarea";
import CustomInput from "../components/CustomInput";
import CustomCheckbox from "../components/CustomCheckbox";
import { IComponent } from "../../../Component";

export const FORM_TENNIS = "form_tennis";

export interface FormTennisSpec {
  type: typeof FORM_TENNIS;
}

const MultiLine = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
`;

const MultiLineGrow = styled.div`
  flex-grow: 1;
  min-width: 0;
`;

const MultiLineGrowSecond = styled.div`
  flex-grow: 1;
  margin-left: 10px;
  min-width: 0;
`;

const SuccessBox = styled.div`
  max-width: 768px;
  background-color: #1a7f1f;
  padding: 25px;
  color: #fff;
  h1 {
    font-size: 1.5em;
    margin: 0px;
  }
`;

const ErrorBox = styled.div`
  max-width: 768px;
  background-color: #c00d00;
  padding: 25px;
  color: #fff;
  h1 {
    font-size: 1.5em;
    margin: 0px;
  }
`;

const FormBox = styled.div`
  max-width: 768px;
`;

const SectionTitle = styled.h3`
  margin-bottom: 5px;
`;

const SubmitButton = styled.button`
  padding: 10px;
  padding-left: 15px;
  padding-right: 15px;
  background: ${bsrBlue};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  text-decoration: none;
  color: white;
  white-space: nowrap;
  text-overflow: ellipsis;
  border-radius: 3px;
  max-width: fit-content;
  border-width: 0px;
`;

const SubmitBox = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 15px;
  margin-bottom: 5px;
`;

const Component = () => {
  const location = useLocation();
  const {
    register,
    handleSubmit,
    formState,
    control,
  } = useForm();

  const {
    errors,
  } = formState;

  const [success, setSuccess] = useState(false);
  const [err, setErr] = useState(false);
  const onSubmit = (data: any) => {
    data.path = location;
    data.form_id = "3";

    console.log(data);

    submitForm(data)
      .then(() => {
        setSuccess(true);
      })
      .catch(() => {
        setErr(true);
      });
  };

  if (success) {
    return (
      <SuccessBox>
        <h1>Anfrage erhalten</h1>
        <h3>
          Besten Dank für Ihre Anfrage. Das Sekretariat wird Ihre Anfrage
          schnellstmöglich verarbeiten und sich bei Ihnen melden.
        </h3>
      </SuccessBox>
    );
  }

  if (err) {
    return (
      <ErrorBox>
        <h1>Fehler</h1>
        <h3>
          Es ist ein Fehler bei der Verarbeitung aufgetreten. Bitte probieren
          sie es in einigen Minuten noch einmal oder wenden sie sich an
          office@bsrueti.ch.
        </h3>
      </ErrorBox>
    );
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormBox>
        <h1>Anfrage Tennisplatzreservation</h1>
        <CustomRadio
          name="Anrede"
          values={["Frau", "Herr", "andere"]}
          register={register}
          required={true}
          errors={errors}
          formState={formState}
        />
        <MultiLine>
          <MultiLineGrow>
            <CustomInput
              name="Vorname"
              register={register}
              required={true}
              errors={errors}
              control={control}
            />
          </MultiLineGrow>
          <MultiLineGrowSecond>
            <CustomInput
              name="Nachname"
              register={register}
              required={true}
              errors={errors}
              control={control}
            />
          </MultiLineGrowSecond>
        </MultiLine>
        <CustomInput
          name="Strasse"
          register={register}
          required={true}
          errors={errors}
          control={control}
        />
        <MultiLine>
          <CustomInput
            name="PLZ"
            register={register}
            required={true}
            errors={errors}
            control={control}
          />
          <MultiLineGrowSecond>
            <CustomInput
              name="Ort"
              register={register}
              required={true}
              errors={errors}
              control={control}
            />
          </MultiLineGrowSecond>
        </MultiLine>
        <CustomInput
          name="Email"
          type="email"
          register={register}
          required={true}
          errors={errors}
          control={control}
        />
        <CustomInput
          name="Telefon"
          register={register}
          required={true}
          errors={errors}
          control={control}
        />
        <CustomCheckbox
          name="Gewünschter Wochentag"
          values={[
            "Montag",
            "Dienstag",
            "Mittwoch",
            "Donnerstag",
            "Freitag",
            "Samstag",
            "Sonntag",
          ]}
          register={register}
          required={false}
          errors={errors}
          formState={formState}
        />
        <CustomCheckbox
          name="Gewünschte Zeit an Werktagen (Mo-Fr)"
          values={["18.00-19.00", "19.00-20.00", "20.00-21.00"]}
          register={register}
          required={false}
          errors={errors}
          formState={formState}
        />

        <CustomCheckbox
          name="Gewünschte Zeit am (Sa, So)"
          values={[
            "08.00-09.00",
            "10.00-11.00",
            "11.00-12.00",
            "12.00-13.00",
            "13.00-14.00",
            "14.00-15.00",
            "15.00-16.00",
            "16.00-17.00",
            "17.00-18.00",
            "18.00-19.00",
            "19.00-20.00",
            "20.00-21.00",
          ]}
          register={register}
          required={false}
          errors={errors}
          formState={formState}
        />
        <SectionTitle>Bemerkungen</SectionTitle>
        <CustomTextarea
          name="Bemerkungen"
          register={register}
          required={false}
          errors={errors}
          formState={formState}
        />
        <SubmitBox>
          <SubmitButton type="submit">Anfrage senden</SubmitButton>
        </SubmitBox>
      </FormBox>
    </form>
  );
};

export const FormTennisComponent: IComponent<FormTennisSpec> = {
  component: Component,
};
