import React, { useState } from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components/macro";
import { bsrBlue } from "../../bsr-defs";
import { submitForm } from "../../../api";
import { useLocation } from "../../../router";
import "moment/locale/de";
import CustomRadio from "../components/CustomRadio";
import CustomTextarea from "../components/CustomTextarea";
import CustomInput from "../components/CustomInput";
import CustomCheckbox from "../components/CustomCheckbox";
import { IComponent } from "../../../Component";
import CustomSelect from "../components/CustomSelect";

export const FORM_WEITERBILDUNG_BERUFSBILDNER =
  "form_weiterbildung_berufsbildner";

export interface FormWeiterbildungBerufsbildnerSpec {
  type: typeof FORM_WEITERBILDUNG_BERUFSBILDNER;
}

const MultiLine = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
`;

const MultiLineGrow = styled.div`
  flex-grow: 1;
  min-width: 0;
`;

const MultiLineGrowSecond = styled.div`
  flex-grow: 1;
  margin-left: 10px;
  min-width: 0;
`;

const SuccessBox = styled.div`
  max-width: 768px;
  background-color: #1a7f1f;
  padding: 25px;
  color: #fff;
  h1 {
    font-size: 1.5em;
    margin: 0px;
  }
`;

const ErrorBox = styled.div`
  max-width: 768px;
  background-color: #c00d00;
  padding: 25px;
  color: #fff;
  h1 {
    font-size: 1.5em;
    margin: 0px;
  }
`;

const FormBox = styled.div`
  max-width: 768px;
`;

const SectionTitle = styled.h3`
  margin-bottom: 0px;
  margin-top: 28px;
`;

const SubmitButton = styled.button`
  padding: 10px;
  padding-left: 15px;
  padding-right: 15px;
  background: ${bsrBlue};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  text-decoration: none;
  color: white;
  white-space: nowrap;
  text-overflow: ellipsis;
  border-radius: 3px;
  max-width: fit-content;
  border-width: 0px;
`;

const SubmitBox = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 15px;
  margin-bottom: 5px;
`;

const InfoText = styled.p`
  margin-bottom: 0px;
  margin-top: 20px;
`;

const Pflichtfeld = styled.div`
  font-size: 0.8em;
  color: grey;
`;

const PflichtfeldBox = styled.div`
  display: flex;
  justify-content: right;
`;

const Component = () => {
  const location = useLocation();
  const { register, watch, handleSubmit, formState, control } = useForm();

  const { errors } = formState;

  const [success, setSuccess] = useState(false);
  const [err, setErr] = useState(false);
  const onSubmit = (data: any) => {
    data.path = location;
    data.form_id = "5";

    console.log(data);

    submitForm(data)
      .then(() => {
        setSuccess(true);
      })
      .catch(() => {
        setErr(true);
      });
  };

  if (success) {
    return (
      <SuccessBox>
        <h1>Anmeldung erfolgreich</h1>
        <h3>
          Besten Dank für Ihre Anmeldung. Das Sekretariat wird Ihre Anmeldung
          schnellstmöglich verarbeiten und sich bei Ihnen melden.
        </h3>
      </SuccessBox>
    );
  }

  if (err) {
    return (
      <ErrorBox>
        <h1>Fehler</h1>
        <h3>
          Es ist ein Fehler bei der Verarbeitung aufgetreten. Bitte probieren
          sie es in einigen Minuten noch einmal oder wenden sie sich an
          office@bsrueti.ch.
        </h3>
      </ErrorBox>
    );
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormBox>
        <h1>Anmeldung Berufsbildner</h1>
        <input
          type="hidden"
          value="280 - Berufsbildner"
          {...register("Kurs")}
        />
        <SectionTitle>Personalien</SectionTitle>
        <PflichtfeldBox>
          <Pflichtfeld>* Pflichtfeld</Pflichtfeld>
        </PflichtfeldBox>
        <CustomRadio
          name="Anrede"
          label="Anrede *"
          values={["Frau", "Herr", "andere"]}
          register={register}
          required={true}
          errors={errors}
          formState={formState}
          style={{ marginTop: "0px" }}
        />
        <MultiLine>
          <MultiLineGrow>
            <CustomInput
              name="Vorname"
              label="Vorname *"
              register={register}
              required={true}
              errors={errors}
              control={control}
            />
          </MultiLineGrow>
          <MultiLineGrowSecond>
            <CustomInput
              name="Nachname"
              label="Nachname *"
              register={register}
              required={true}
              errors={errors}
              control={control}
            />
          </MultiLineGrowSecond>
        </MultiLine>
        <CustomInput
          name="Strasse"
          label="Strasse *"
          register={register}
          required={true}
          errors={errors}
          control={control}
        />
        <MultiLine>
          <CustomInput
            name="PLZ"
            label="PLZ *"
            register={register}
            required={true}
            errors={errors}
            control={control}
          />
          <MultiLineGrowSecond>
            <CustomInput
              name="Ort"
              label="Ort *"
              register={register}
              required={true}
              errors={errors}
              control={control}
            />
          </MultiLineGrowSecond>
        </MultiLine>
        <CustomSelect
          name="Kanton"
          label="Kanton *"
          values={[
            "Aargau",
            "Appenzell Innerrhoden",
            "Appenzell Ausserrhoden",
            "Basel-Landschaft",
            "Basel-Stadt",
            "Bern",
            "Freiburg",
            "Genf",
            "Glarus",
            "Graubünden",
            "Jura",
            "Luzern",
            "Neuenburg",
            "Nidwalden",
            "Obwalden",
            "Schaffhausen",
            "Schwyz",
            "Solothurn",
            "St. Gallen",
            "Tessin",
            "Thurgau",
            "Uri",
            "Waadt",
            "Wallis",
            "Zug",
            "Zürich",
          ]}
          register={register}
          required={true}
          errors={errors}
          control={control}
        />
        <CustomInput
          name="Email"
          label="Email *"
          type="email"
          register={register}
          required={true}
          errors={errors}
          control={control}
        />
        <CustomInput
          name="Telefon"
          register={register}
          required={false}
          errors={errors}
          control={control}
        />
        <CustomInput
          name="Geburtsdatum"
          label="Geburtsdatum *"
          register={register}
          required={true}
          errors={errors}
          control={control}
        />
        <CustomInput
          name="AHV"
          label="AHV-Nummer *"
          register={register}
          required={true}
          errors={errors}
          control={control}
        />
        <CustomInput
          name="Beruf"
          label="Beruf, den Sie ausbilden *"
          register={register}
          required={true}
          errors={errors}
          control={control}
        />

        <SectionTitle>Arbeitgeber</SectionTitle>
        <CustomInput
          name="Arbeitgeber"
          label="Arbeitgeber *"
          register={register}
          required={true}
          errors={errors}
          control={control}
        />
        <CustomInput
          name="StrasseArbeitgeber"
          label="Strasse *"
          register={register}
          required={true}
          errors={errors}
          control={control}
        />
        <MultiLine>
          <CustomInput
            name="PLZArbeitgeber"
            label="PLZ *"
            register={register}
            required={true}
            errors={errors}
            control={control}
          />
          <MultiLineGrowSecond>
            <CustomInput
              name="OrtArbeitgeber"
              label="Ort *"
              register={register}
              required={true}
              errors={errors}
              control={control}
            />
          </MultiLineGrowSecond>
        </MultiLine>
        <CustomRadio
          name="RechnungArbeitgeber"
          label="Rechnung ausgestellt auf Arbeitgeber *"
          values={["Ja", "Nein"]}
          register={register}
          required={true}
          errors={errors}
          formState={formState}
        />

        <SectionTitle>Lehrmittel</SectionTitle>
        <InfoText>
          Das Lehrmittel können Sie bei SDBB - Schweiz. Dienstleistungszentrum
          Berufsbildung, Berufs- und Studien- und Laufbahnberatung, bestellen:
          <br />
          <a
            href="https://www.berufsbildung.ch/de/voraussetzungen/handbuch-betriebliche-grundbildung"
            target="_blank"
          >
            https://www.berufsbildung.ch/de/voraussetzungen/handbuch-betriebliche-grundbildung
          </a>
          <br />
          <b>Das Lehrmittel muss am ersten Kurstag vorhanden sein.</b>
        </InfoText>

        <SectionTitle>Bemerkungen</SectionTitle>
        <CustomTextarea
          name="Bemerkungen"
          register={register}
          required={false}
          errors={errors}
          formState={formState}
        />
        <SubmitBox>
          <SubmitButton type="submit">Anmeldung senden</SubmitButton>
        </SubmitBox>
      </FormBox>
    </form>
  );
};

export const FormWeiterbildungBerufsbildnerComponent: IComponent<FormWeiterbildungBerufsbildnerSpec> =
  {
    component: Component,
  };
